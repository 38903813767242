import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import moment from "moment"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import "./blog-post.css"


const ArticleDate = styled.div`
  padding-bottom: 0.5rem;
  color: #777;
`

const ArticleHeader = styled.div`
  @media (max-width: 540px) {
    h1 {
      margin-top: 0.3rem;
    }
  }
`

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const title = post.frontmatter.title
  const date = post.frontmatter.date
  const formattedDate = moment(date).format("MMMM Do YYYY")

  return (
    <PageLayout showAffiliateLink>
      <SEO title={title} description={post.excerpt} />
      <div>
        <ArticleHeader>
          <h1>{title}</h1>
          <ArticleDate>
            Published: {formattedDate}
          </ArticleDate>
        </ArticleHeader>
        <div 
          className="article-content" 
          dangerouslySetInnerHTML={{ __html: post.html }} 
        />
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
      }
      excerpt
    }
  }
`
